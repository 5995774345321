import React, { useState } from "react";
import NavBarDesktop from "../navbarNews/navbarNewsDesktop";
import "./campaign.css";
import Frame from '../../assets/Frame 2610381.jpg';
import One from "../../assets/campaign-frame1.svg";
import Two from "../../assets/campaign-frame2.svg";
import Three from "../../assets/campaign-frame3.svg";
import Four from "../../assets/campaign-frame4.svg";
import Five from "../../assets/campaign-frame5.svg";
import Six from "../../assets/campaign-frame6.svg";
import Seven from "../../assets/campaign-frame7.svg";
import Eight from "../../assets/campaign-frame8.svg";
import Nine from "../../assets/campaign-frame9.svg";
import ExampleOne from "../../assets/campaign-example-1.svg";
import ExampleTwo from "../../assets/campaign-example-2.svg";
import ExampleThree from "../../assets/campaign-example-3.svg";
import ExampleFour from "../../assets/campaign-example-4.svg";
import ExampleFive from "../../assets/campaign-example-5.svg";
import ExampleSix from "../../assets/campaign-example-6.svg";
import Burger from '../../assets/delicious-burguer-isolated-white-background.jpg';
import Middle from "../../assets/campaign-middle.svg";
import MiddleMobile from "../../assets/campaign-mobile-left.svg";
import Footer from "../footer/footer";

const Campaign = ({ isEnglish, setIsEnglish }) => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const centerY = rect.height / 2;
    const maxRotationX = 5;
    const rotationAngleX = -((y - centerY) / centerY) * maxRotationX;

    const centerX = rect.width / 2;
    const maxRotationY = 10;
    const rotationAngleY = -((x - centerX) / centerX) * maxRotationY;

    e.currentTarget.style.transform = `rotateX(${rotationAngleX}deg) rotateY(${rotationAngleY}deg)`;

    setCursorPosition({ x, y });
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.transform = `rotateX(0deg) rotateY(0deg)`;
    setCursorPosition({ x: 0, y: 0 });
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="container">
      <NavBarDesktop
        direction={true}
        isEnglish={isEnglish}
        setIsEnglish={setIsEnglish}
      />
      <div className="header-text-placement">
        <p className="header-text">
          {isEnglish
            ? "Curious how ONERI manages to optimize contract management and the entire Make your workflow uninterrupted from start to finish? Here's a look behind the scenes!"
            : "Neugierig, wie ONERI es schafft, das Vertragsmanagement zu optimieren und den gesamten Workflow von Anfang bis Ende ununterbrochen zu gestalten? Hier ist ein Blick hinter den Kulissen!"}
        </p>
      </div>
      <div className="campaign-center-header">
        <div className="title-left">
          <p className="title-text">
            {isEnglish ? "New contracts" : "Neu Verträge"}
          </p>
        </div>
        <div className="title-center">
          <img src={One} />
        </div>
        <div className="title-right">
          <p className="title-text-mobile">
            {isEnglish ? "New contracts" : "Neu Verträge"}
          </p>
        </div>
      </div>
      <div className="campaign-center">
        <div className="content-left">
          <div
            className="campaign-text-box"
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          >
            <div className="campaign-text-box-in">
              <p className="text-box-title">
                {isEnglish ? "Contract Creation" : "Vertragserstellung"}
              </p>
              <p className="text-box-text">
                {isEnglish
                  ? "When a new contract is created by a seller, it's not just a document - it's an opportunity! The entire process can also be done conveniently via our mobile app. Once the contract is created, it is transferred to our ONERI system in real time, so every contract is tracked, monitored and ready for the next step. No delays, no missing data."
                  : "Vertragserstellung Wenn ein neuer Vertrag von einem Verkäufer erstellt wird, ist es nicht nur ein Dokument – es ist eine Gelegenheit! Der gesamte Prozess kann auch bequem über unsere mobile App erfolgen. Sobald derVertrag erstellt wurde, wird er in Echtzeit in unser ONERI-System übertragen, sodass jeder Vertrag verfolgt, überwacht und für den nächsten Schritt bereit ist. Keine Verzögerungen, keine fehlenden Daten."}
              </p>
            </div>
          </div>
        </div>
        <div className="title-center">
          <img src={Middle} className="middle-windows" />
          <img src={MiddleMobile} className="middle-phone" />
        </div>
        <div className="content-right">
          <div className="campaign-text-box-mobile">
            <div className="campaign-text-box-in">
              <p className="text-box-title">
                {isEnglish ? "Contract Creation" : "Vertragserstellung"}
              </p>
              <p className="text-box-text">
                {isEnglish
                  ? "When a new contract is created by a seller, it's not just a document - it's an opportunity! The entire process can also be done conveniently via our mobile app. Once the contract is created, it is transferred to our ONERI system in real time, so every contract is tracked, monitored and ready for the next step. No delays, no missing data."
                  : "Vertragserstellung Wenn ein neuer Vertrag von einem Verkäufer erstellt wird, ist es nicht nur ein Dokument – es ist eine Gelegenheit! Der gesamte Prozess kann auch bequem über unsere mobile App erfolgen. Sobald derVertrag erstellt wurde, wird er in Echtzeit in unser ONERI-System übertragen, sodass jeder Vertrag verfolgt, überwacht und für den nächsten Schritt bereit ist. Keine Verzögerungen, keine fehlenden Daten."}
              </p>
            </div>
          </div>
          <img
            src={Frame}
            className="example-image"
            onClick={() => handleImageClick(Burger)}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          />
        </div>
      </div>
      <div className="campaign-center">
        <div className="title-left"></div>
        <div className="title-center">
          <img src={Two} />
        </div>
        <div className="title-right">
          <p className="title-text">
            {isEnglish ? "Real time process" : "Echtzeitprozess"}
          </p>
          <p className="title-text-mobile">
            {isEnglish ? "Real time process" : "Echtzeitprozess"}
          </p>
        </div>
      </div>
      <div className="campaign-center">
        <div className="content-left">
          <img
            src={ExampleTwo}
            className="example-image-left"
            onClick={() => handleImageClick(ExampleTwo)}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          />
        </div>
        <div className="title-center">
          <img src={Middle} className="middle-windows" />
          <img src={MiddleMobile} className="middle-phone" />
        </div>
        <div className="content-right">
          <div
            className="campaign-text-box"
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          >
            <div className="campaign-text-box-in">
              <p className="text-box-title">
                {isEnglish
                  ? "Back office verification"
                  : "Backoffice-Überprüfung"}
              </p>
              <p className="text-box-text">
                {isEnglish
                  ? "Each contract then undergoes a thorough review by the provider’s back office team. Here we ensure that everything is correct, complete and compliant. The goal? To ensure the highest quality and ensure a seamless experience for customers and agents."
                  : "Jeder Vertrag macht anschließend eine gründliche Überprüfung durch das Backoffice-Team des Anbieters. Hier stellen wir sicher, dass alles korrekt, vollständig und regelkonform ist. Das Ziel? Höchste Qualität zu gewährleisten und ein reibungsloses Erlebnis für Kunden und Agenten sicherzustellen."}
              </p>
            </div>
          </div>
          <div className="campaign-text-box-mobile">
            <div className="campaign-text-box-in">
              <p className="text-box-title">
                {isEnglish
                  ? "Back office verification"
                  : "Backoffice-Überprüfung"}
              </p>
              <p className="text-box-text">
                {isEnglish
                  ? "Each contract then undergoes a thorough review by the provider’s back office team. Here we ensure that everything is correct, complete and compliant. The goal? To ensure the highest quality and ensure a seamless experience for customers and agents."
                  : "Jeder Vertrag macht anschließend eine gründliche Überprüfung durch das Backoffice-Team des Anbieters. Hier stellen wir sicher, dass alles korrekt, vollständig und regelkonform ist. Das Ziel? Höchste Qualität zu gewährleisten und ein reibungsloses Erlebnis für Kunden und Agenten sicherzustellen."}
              </p>
            </div>
          </div>
          <img
            src={ExampleTwo}
            className="example-image-left-show"
            onClick={() => handleImageClick(ExampleTwo)}
          />
        </div>
      </div>
      <div className="campaign-center-two">
        <div className="title-left-two">
          <p className="title-text">
            {isEnglish ? "User Controlled" : "Benutzer Gesteuert"}
          </p>
        </div>
        <div className="title-center-two">
          <img src={Three} />
          <img src={Four} />
        </div>
        <div className="title-center-two-mobile">
          <div className="icon-title">
            <img src={Three} />
            <p className="title-text-mobile-2">
              {isEnglish ? "User Controlled" : "Benutzer Gesteuert"}
            </p>
          </div>
          <div className="icon-title">
            <img src={Four} />
            <p className="title-text-mobile-2">
              {isEnglish ? "Order status" : "Bestellstatus"}
            </p>
          </div>
        </div>
        <div className="title-right-two">
          <p className="title-text">
            {isEnglish ? "Order status" : "Bestellstatus"}
          </p>
        </div>
      </div>
      <div className="campaign-center">
        <div className="content-left">
          <div
            className="campaign-text-box"
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          >
            <div className="campaign-text-box-in">
              <p className="text-box-title">
                {isEnglish
                  ? "Assignment of contract status"
                  : "Vergabe des Vertragsstatus"}
              </p>
              <p className="text-box-text">
                {isEnglish
                  ? "Once reviewed, each contract is assigned a specific status based on its current status in the process. This means that the contract is either pending, approved, incomplete or ready to be submitted. This transparent status assignment helps teams set their priorities and focus on what is important."
                  : "Nach der Überprüfung wird jedem Vertrag ein bestimmter Status zugewiesen, basierend auf seinem aktuellen Stand im Prozess. Das bedeutet, dass der Vertrag entweder noch ausstehend, genehmigt, unvollständig oder bereit zur Übermittlung ist. Diese transparente Statusvergabe hilft den Teams, ihre Prioritäten zu setzen und sich auf das Wesentliche zu konzentrieren."}
              </p>
            </div>
          </div>
        </div>
        <div className="title-center">
          <img src={Middle} className="middle-windows" />
          <img src={MiddleMobile} className="middle-phone" />
        </div>
        <div className="content-right">
          <div className="campaign-text-box-mobile">
            <div className="campaign-text-box-in">
              <p className="text-box-title">
                {isEnglish
                  ? "Assignment of contract status"
                  : "Vergabe des Vertragsstatus"}
              </p>
              <p className="text-box-text">
                {isEnglish
                  ? "Once reviewed, each contract is assigned a specific status based on its current status in the process. This means that the contract is either pending, approved, incomplete or ready to be submitted. This transparent status assignment helps teams set their priorities and focus on what is important."
                  : "Nach der Überprüfung wird jedem Vertrag ein bestimmter Status zugewiesen, basierend auf seinem aktuellen Stand im Prozess. Das bedeutet, dass der Vertrag entweder noch ausstehend, genehmigt, unvollständig oder bereit zur Übermittlung ist. Diese transparente Statusvergabe hilft den Teams, ihre Prioritäten zu setzen und sich auf das Wesentliche zu konzentrieren."}
              </p>
            </div>
          </div>
          <img
            src={ExampleThree}
            className="example-image"
            onClick={() => handleImageClick(ExampleThree)}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          />
        </div>
      </div>
      <div className="campaign-center-two">
        <div className="title-left-two"></div>
        <div className="title-center-two">
          <img src={Five} />
          <img src={Six} />
        </div>
        <div className="title-center-two-mobile">
          <div className="icon-title">
            <img src={Five} />
            <p className="title-text-mobile-2">
              {isEnglish ? "Customers confirm" : "Kunden Bestätigen"}
            </p>
          </div>
          <div className="icon-title">
            <img src={Six} />
            <p className="title-text-mobile-2">
              {isEnglish ? "Multiple files" : "Mehrere Dateien"}
            </p>
          </div>
        </div>
        <div className="title-right-two">
          <p className="title-text">
            {isEnglish ? "Customers confirm" : "Kunden Bestätigen"}
          </p>
        </div>
      </div>
      <div className="campaign-center">
        <div className="content-left">
          <img
            src={ExampleFour}
            className="example-image"
            onClick={() => handleImageClick(ExampleFour)}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          />
        </div>
        <div className="title-center">
          <img src={Middle} className="middle-windows" />
          <img src={MiddleMobile} className="middle-phone" />
        </div>
        <div className="content-right">
          <div
            className="campaign-text-box"
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          >
            <div className="campaign-text-box-in">
              <p className="text-box-title">
                {isEnglish
                  ? "Forwarding to suppliers"
                  : " Weiterleitung an Lieferanten"}
              </p>
              <p className="text-box-text">
                {isEnglish
                  ? "After approval by the back office, the contracts are automatically forwarded to our partners who use the system. This integration allows us to deliver great performance easily while keeping everyone involved up to date."
                  : "Nach der Freigabe durch das Backoffice werden die Verträge automatisch an unsere Partner, die das System nutzen, weitergeleitet. Diese Integration ermöglicht es uns, große Leistung problemlos zu erbringen, während alle Beteiligten stets auf dem neuesten Stand bleiben."}
              </p>
            </div>
          </div>
          <div className="campaign-text-box-mobile">
            <div className="campaign-text-box-in">
              <p className="text-box-title">
                {isEnglish
                  ? "Forwarding to suppliers"
                  : " Weiterleitung an Lieferanten"}
              </p>
              <p className="text-box-text">
                {isEnglish
                  ? "After approval by the back office, the contracts are automatically forwarded to our partners who use the system. This integration allows us to deliver great performance easily while keeping everyone involved up to date."
                  : "Nach der Freigabe durch das Backoffice werden die Verträge automatisch an unsere Partner, die das System nutzen, weitergeleitet. Diese Integration ermöglicht es uns, große Leistung problemlos zu erbringen, während alle Beteiligten stets auf dem neuesten Stand bleiben."}
              </p>
            </div>
          </div>
          <img
            src={ExampleFour}
            className="example-image-left-show"
            onClick={() => handleImageClick(ExampleFour)}
          />
        </div>
      </div>
      <div className="campaign-center-two">
        <div className="title-left-two">
          <p className="title-text">{isEnglish ? "Calculate" : "Berechnen"}</p>
        </div>
        <div className="title-center-two">
          <img src={Seven} />
          <img src={Eight} />
        </div>
        <div className="title-center-two-mobile">
          <div className="icon-title">
            <img src={Seven} />
            <p className="title-text-mobile-2">
              {isEnglish ? "Calculate" : "Berechnen"}
            </p>
          </div>
          <div className="icon-title">
            <img src={Eight} />
            <p className="title-text-mobile-2">
              {isEnglish ? "Processing" : "Verarbeitung"}
            </p>
          </div>
        </div>
        <div className="title-right-two">
          <p className="title-text">
            {isEnglish ? "Processing" : "Verarbeitung"}
          </p>
        </div>
      </div>
      <div className="campaign-center">
        <div className="content-left">
          <div
            className="campaign-text-box"
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          >
            <div className="campaign-text-box-in">
              <p className="text-box-title">
                {isEnglish
                  ? "Generation and commission accounting"
                  : "Generierung und Provisionsabrechnung"}
              </p>
              <p className="text-box-text">
                {isEnglish
                  ? "ONERI also allows the selection of multiple contracts for automated billing creation in the back office module. This means that with just a few clicks the system will show the exact earnings the agent charges based on their contract performance, score or volume - making payment processing faster, more accurate and less complicated"
                  : "ONERI ermöglicht auch die Auswahl mehrerer Verträge zur automatisierten Abrechnungserstellung im Backoffice-Modul. Das bedeutet, dass mit nur wenigen Klicks das System die genauen Einnahmen der Agenten berechnet, basierend auf ihrer Vertragsleistung, Punktzahl oder dem Volumen – was die Zahlungsabwicklung schneller, präziser und unkomplizierter macht."}
              </p>
            </div>
          </div>
        </div>
        <div className="title-center">
          <img src={Middle} className="middle-windows" />
          <img src={MiddleMobile} className="middle-phone" />
        </div>
        <div className="content-right">
          <div className="campaign-text-box-mobile">
            <div className="campaign-text-box-in">
              <p className="text-box-title">
                {isEnglish
                  ? "Generation and commission accounting"
                  : "Generierung und Provisionsabrechnung"}
              </p>
              <p className="text-box-text">
                {isEnglish
                  ? "ONERI also allows the selection of multiple contracts for automated billing creation in the back office module. This means that with just a few clicks the system will show the exact earnings the agent charges based on their contract performance, score or volume - making payment processing faster, more accurate and less complicated"
                  : "ONERI ermöglicht auch die Auswahl mehrerer Verträge zur automatisierten Abrechnungserstellung im Backoffice-Modul. Das bedeutet, dass mit nur wenigen Klicks das System die genauen Einnahmen der Agenten berechnet, basierend auf ihrer Vertragsleistung, Punktzahl oder dem Volumen – was die Zahlungsabwicklung schneller, präziser und unkomplizierter macht."}
              </p>
            </div>
          </div>
          <img
            src={ExampleFive}
            className="example-image"
            onClick={() => handleImageClick(ExampleFive)}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          />
        </div>
      </div>
      <div className="campaign-center-two">
        <div className="title-left-two"></div>
        <div className="title-center-two">
          <img src={Nine} />
        </div>
        <div className="title-center-two-mobile">
          <div className="icon-title">
            <img src={Nine} />
            <p className="title-text-mobile-2">
              {isEnglish ? "Credit" : "Anrechung"}
            </p>
          </div>
        </div>
        <div className="title-right-two"></div>
      </div>
      <div className="campaign-center-content">
        <p className="title-text">{isEnglish ? "Credit" : "Anrechung"}</p>
        <div className="text-image">
          <div
            className="campaign-text-box"
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          >
            <div className="campaign-text-box-in">
              <p className="text-box-title">
                {isEnglish ? "Contract Creation" : "Vertragserstellung"}
              </p>
              <p className="text-box-text">
                {isEnglish
                  ? "Sales control via the sales tracker module The ONERI sales tracker module enables active control of sales and targeted management of customer and lead lists. It offers features such as importing lead lists, tracking processing status, and assigning leads to employees. In addition, geographical sales analysis enables a precise evaluation of sales performance in projects. With these tools, leads can be optimally prioritized and sales activities can be effectively controlled to maximize success in direct sales."
                  : "Vertriebssteuerung über das Sales-Tracker-Modul Das ONERI Sales-Tracker-Modul ermöglicht eine aktive Steuerung des Vertriebs und gezieltes Management von Kunden- und Leadlisten. Es bietet Funktionen wie den Import von Leadlisten, die Verfolgung des Bearbeitungsstatus und die Zuweisung von Leads an Mitarbeiter. Zusätzlich ermöglicht die geographische Vertriebsanalyse eine präzise Auswertung der Vertriebsperformance in Projekten. Mit diesen Werkzeugen können Leads optimal priorisiert und Vertriebsaktivitäten wirkungsvoll gesteuert werden, um den Erfolg im Direktvertrieb zu maximieren."}
              </p>
            </div>
          </div>
          <div className="campaign-text-box-mobile">
            <div className="campaign-text-box-in">
              <p className="text-box-title">
                {isEnglish ? "Contract Creation" : "Vertragserstellung"}
              </p>
              <p className="text-box-text">
                {isEnglish
                  ? "Sales control via the sales tracker module The ONERI sales tracker module enables active control of sales and targeted management of customer and lead lists. It offers features such as importing lead lists, tracking processing status, and assigning leads to employees. In addition, geographical sales analysis enables a precise evaluation of sales performance in projects. With these tools, leads can be optimally prioritized and sales activities can be effectively controlled to maximize success in direct sales."
                  : "Vertriebssteuerung über das Sales-Tracker-Modul Das ONERI Sales-Tracker-Modul ermöglicht eine aktive Steuerung des Vertriebs und gezieltes Management von Kunden- und Leadlisten. Es bietet Funktionen wie den Import von Leadlisten, die Verfolgung des Bearbeitungsstatus und die Zuweisung von Leads an Mitarbeiter. Zusätzlich ermöglicht die geographische Vertriebsanalyse eine präzise Auswertung der Vertriebsperformance in Projekten. Mit diesen Werkzeugen können Leads optimal priorisiert und Vertriebsaktivitäten wirkungsvoll gesteuert werden, um den Erfolg im Direktvertrieb zu maximieren."}
              </p>
            </div>
          </div>
          <img
            src={ExampleSix}
            className="example-image-last"
            onClick={() => handleImageClick(ExampleSix)}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          />
        </div>
        <p className="footer-text">
          {isEnglish
            ? "Through ONERI's end-to-end solution, we transform the entire Contract lifecycle into an open, automated and transparent process, which is reflected in the productivity of the teams increases and ensures that every contract counts!"
            : "Durch die End-to-End-Lösung von ONERI verwandeln wir den gesamten Vertragslebenszyklus in einen offenen, automatisierten und transparenten Prozess, welcher sich durch die Produktivität der Teams steigert und stellen sicher, dass jeder Vertrag zählt!"}
        </p>
      </div>
      <Footer isEnglish={isEnglish} />

      {selectedImage && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closeModal}>
              &times;
            </button>
            <img src={selectedImage} alt="Full size" className="modal-image" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Campaign;

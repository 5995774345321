import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import "./insideNews.css";
import NavBarDesktop from "../navbarNews/navbarNewsDesktop";
import Footer from "../footer/footer";
import Arrow from "../../assets/arrow-left.svg";
import WrongLinkImg from "../../assets/WrongLink.svg";
import RightArrow from "../../assets/RightArrow.svg";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";

const BASE_URL = "https://oneri.tframe.de";

const InsideNews = ({ isEnglish, setIsEnglish }) => {
  const location = useLocation();
  const { slug } = useParams();
  const navigate = useNavigate();
  const [newsItem, setNewsItem] = useState(location.state?.item || null);

  useEffect(() => {
    const fetchNewsBySlug = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/api/news?filters[slug][$eq]=${decodeURIComponent(
            slug
          )}&populate=headerImage,contentImage`,
          {
            headers: {
              Authorization:
                "Bearer bc5cb0b519649befe8d38eaa1e360c6b3c16ee7997c7fc74612bf2769f2ee0772ad5d1e93a281eba7a4e88d02f4fc87a39fccc8fda917a19f2f14b8663d0ccb99bad842d62c8c9771dd953af024fe0b5882108e22ba14207d5b8950ce0dd2481c092e5f32586f79f88d317607a1c0dafa2ae361494d7ad90ac0419b5c1dff9e6",
            },
          }
        );
        const data = await response.json();
        setNewsItem(data.data[0]);
      } catch (error) {
        console.error("Error fetching news data:", error);
      }
    };

    if (!newsItem) {
      fetchNewsBySlug();
    }
  }, [slug, newsItem]);

  if (!newsItem) {
    return (
      <div className="container">
        <NavBarDesktop
          direction={true}
          isEnglish={isEnglish}
          setIsEnglish={setIsEnglish}
        />
        <div className="bodyInsideNews1">
          <img className="wrongImg" src={WrongLinkImg} />
          <h1 className="wrongText">
            {isEnglish
              ? "The page you’re looking for can’t be found."
              : "Die gesuchte Seite kann nicht gefunden werden."}
          </h1>
          <button
            className={isEnglish ? "WrongButtonEn" : "WrongButtonDe"}
            onClick={() => navigate("/news")}
          >
            <h1>
              {isEnglish
                ? "Or see our news"
                : "Oder sehen Sie sich unsere Neuigkeiten an"}
            </h1>
            <img src={RightArrow} />
          </button>
        </div>
        <Footer />
      </div>
    );
  }

  const headerImage = newsItem.attributes.headerImage?.data?.attributes?.url
    ? `${BASE_URL}${newsItem.attributes.headerImage.data.attributes.url}`
    : "";
  const description = newsItem.attributes.description || [];
  const descriptionDE = newsItem.attributes.descriptionDE || [];
  const additionalImages = newsItem.attributes.contentImage?.data || [];

  const extractMarkdownText = (richText) => {
    if (!Array.isArray(richText)) return "";
    return richText
      .map((block) => {
        if (Array.isArray(block.children)) {
          return block.children.map((child) => child.text).join("");
        }
        return "";
      })
      .join("\n\n");
  };

  const descriptionText = isEnglish
    ? extractMarkdownText(description)
    : extractMarkdownText(descriptionDE);

  return (
    <div className="container">
      <NavBarDesktop
        direction={true}
        isEnglish={isEnglish}
        setIsEnglish={setIsEnglish}
      />
      <div className="bodyInsideNews">
        <div className="bigImage">
          {headerImage && (
            <img src={headerImage} className="image1" alt="News Header" />
          )}
          <div className="titleOverlay">
            <h1 className="TitleOverlayText">{newsItem.attributes.title}</h1>
          </div>
        </div>
        <div className="text">
          <div className="backButton">
            <button className="newsButton1" onClick={() => navigate("/news")}>
              <img src={Arrow} alt="Back" className="arrowIcon" />
              <h1 className="text2">{isEnglish ? "News" : "Nachrichten"}</h1>
              <h1 className="separator"> | </h1>
              <h1 className="text2 title">{newsItem.attributes.title}</h1>
            </button>
          </div>

          <div>
            <BlocksRenderer content={description} />
            {additionalImages.map((image, index) => {
              const imageUrl = image.attributes.url
                ? `${BASE_URL}${image.attributes.url}`
                : null;
              return imageUrl ? (
                <img
                  key={index}
                  src={imageUrl}
                  className="image2"
                  alt={`Additional Image ${index + 1}`}
                />
              ) : null;
            })}
          </div>
        </div>
      </div>
      <Footer isEnglish={isEnglish} />
    </div>
  );
};

export default InsideNews;
